import React, {useState, useEffect} from 'react';
import * as S from "./styles";
import { TextField, FormControl, Card, CardContent, Select, MenuItem, InputLabel, Paper,  Box, Typography, Tabs, Tab, ListItem, List, ListItemText,ListItemAvatar, Avatar, Divider,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@material-ui/core";

import Pagination from '@material-ui/lab/Pagination';

import { Loading } from "../Loading/index";

import { usePasta } from "../../hooks/pasta";
import { useTarefa } from "../../hooks/tarefa";

import {
    useParams,
    useHistory
} from "react-router-dom";


import { DownloadRelatorioModal } from "../../components/DownloadRelatorioModal/index.js"
import { HistoricoComponent } from "../HistoricoComponent/index";
import { SearchTarefaModal } from './search';

import Draggable from 'react-draggable';
// import { TrendingUpRounded } from "@material-ui/icons";

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
    
}




export function FormEditaCliente({verModalHistorico}) {
    const { editCliente, isLoadingCliente, verClientePorUuid, VisualizarClientePorUUID, empresaListaAtiva, VisualizarEmpresa, paginationTarefa,  verTarefaPorUuid, isLoadingTarefa, tarefaPageCliente, VisualizarTarefaPorUUID, deletarTarefa, verHistoricoPorUuid, visualizarHistoricoPorCliente} = usePasta();
    const { downloadRelatorioTarefa } = useTarefa();
    
    let history = useHistory();
    const { id } = useParams();

    /*  Dados do cliente */
    const [nome, setNome] = useState(verClientePorUuid[0]?.nome || '');
    const [status, setStatus] = useState(verClientePorUuid[0]?.status || '');
    const [empresa, setEmpresa] = useState(verClientePorUuid[0]?.empresa || '');

    
    const [clienteRender, setClienteRender] = useState(true);
    const [tarefaRender, setTarefaRender] = useState(true);
    const [historicoRender, setHistoricoRender] = useState(true);

    const [busca, setBusca] = useState(false);

    const [tab, setTab] = useState('');
    const [checkTab, setCheckTab] = useState(false);

    const master = Number(localStorage.getItem('MMPROWORK'));
    const administrador = Number(localStorage.getItem('MPROWORK'));
    const gerente = Number(localStorage.getItem('GTPROWORK'));
    const gestor = Number(localStorage.getItem('GSPROWORK'));
    const analista = Number(localStorage.getItem('ANPROWORK'));

    if(analista === 1 || administrador === 1 || master === 1 || gerente === 1 ||  gestor === 2){
        if(checkTab === false){
            setTab('cliente');
            setCheckTab(true);
        }
    }else if((gestor === 1) && (administrador === 2 || master === 2 || gerente === 2 || analista === 2)){
        if(checkTab === false){ 
            setTab('auditoria');
            setCheckTab(true);
        }
    }

    //PEGA DADOS DO CLIENTE
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchCliente() {
        setClienteRender(false);
        let cliente =  await VisualizarClientePorUUID(id);
        if(cliente.erro === false) {
            setNome(cliente?.dados[0]?.nome);
            setStatus(cliente?.dados[0]?.status);
            setEmpresa(cliente?.dados[0]?.empresa);
        }
        await VisualizarEmpresa();
    }

    //PEGA DADOS DA TAREFA
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchTarefa() {
        setTarefaRender(false);
        await VisualizarTarefaPorUUID(id);
    }
   //PEGA DADOS DA HISTÓRICO
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchHistorico() {
        setHistoricoRender(false);
        await visualizarHistoricoPorCliente(id);
    }

    useEffect( () => {
        if(clienteRender === true && tab === 'cliente'  ){
            fetchCliente();
        }
    }, [id, clienteRender, tab, VisualizarClientePorUUID, VisualizarEmpresa, fetchCliente]);


    useEffect( () => {
        if(tarefaRender === true && tab === 'auditoria' ){
            fetchTarefa();
        }
    }, [id, tarefaRender, tab, VisualizarTarefaPorUUID, fetchTarefa]);


    useEffect( () => {
        if(historicoRender === true && tab === 'historico'){
            fetchHistorico();
        }
    }, [id, historicoRender, tab, visualizarHistoricoPorCliente, fetchHistorico]);

    useEffect(() => {
        localStorage.removeItem('salvarBuscaAuditoria');
    }, [])


    //FUNÇÕES HISTÓRICO

    function handlePrimeiraAba(){
        setClienteRender(true);
        fetchCliente();
    }

    function handleSegundaAba(){
        setTarefaRender(true);
        fetchTarefa();
    }

    function handleTerceiraAba(){
       setHistoricoRender(true);
       fetchHistorico();
    }

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);

        if(newValue === 'cliente'){
            handlePrimeiraAba();
        }else if(newValue === 'auditoria'){
            handleSegundaAba();
        }else if(newValue === 'historico'){
            handleTerceiraAba();
        }

    }; 

    //EDITAR CLIENTE
    const handlEditaCliente = async event => {
        event.preventDefault();

        let data = {
            nome,
            empresa,
            status,
        }

        await editCliente(data, id);
    }

    const handleDownloadRelatorio = async () => {
        let params = localStorage.getItem('salvarBuscaAuditoria') ? localStorage.getItem('salvarBuscaAuditoria') : "?cliente="+id;
        
        await downloadRelatorioTarefa(params, false);
    }


    //AUDITORIA
    const [openConfirm, setOpenConfirmarDeletar] = useState(false);

    const handleSelect = async (event, value) => {
        await tarefaPageCliente(value);
    }
    
    const handleClickAbreEdicaoAuditoria = async (uuid) => {
        history.push("/checklist/"+uuid);
    };


    const [nomeTarefa, setNomeTarefa] = useState('');
    const [uuidTarefa, setUuidTarefa] = useState('');
    const [recorrenteTarefa, setRecorrente] = useState('');

    const handleAbreModalDeletaAuditoria = async (nome, uuid, recorrente) => {
        setOpenConfirmarDeletar(true);
        setNomeTarefa(nome);
        setUuidTarefa(uuid);
        setRecorrente(recorrente);
    };

    const handleFechaModalDeletaAuditoria = async () => {
        setOpenConfirmarDeletar(false);
        setNomeTarefa('');
        setUuidTarefa('');
        setRecorrente('');
    };

    const handleDeletaConfirm = async (uuid, recorrente) => {
        setOpenConfirmarDeletar(false);
    
       let result =  await deletarTarefa(uuid, recorrente);
        
        if(result.erro === false){
            setNomeTarefa('');
            setUuidTarefa('');
            setRecorrente('');
            setTab('auditoria');
            setTarefaRender(true);

        }
    };

    function onNovaAuditoria() {
        history.push("/checklist/novo/"+id);
    }



    /* HISTÓRICO */
    const [abreModalHistoricoCliente, setAbreModalHistoricoCliente] = useState(false);

    function handleAbreModalHistoricoCliente(){
        setAbreModalHistoricoCliente(true);
    }

    function handleFechaHistoricotoModalCliente(){
        setAbreModalHistoricoCliente(false);
    }

    const [abreModalRelatorio, setAbreModalRelatorio] = useState(false);
    const [tabelaRelatorio, setTabelaRelatorio] = useState(false);

    const handleFechaRelatorioModal = () =>{
        setTabelaRelatorio("");
        setAbreModalRelatorio(false);
    }


    return (
        <Box sx={{ width: '100%'}}>     
            <Tabs value={tab} onChange={handleChangeTab} centered style={{ backgroundColor: 'white' }}>

                {analista === 1 || administrador === 1 || master === 1 || gerente === 1 || gestor === 2 ?
                    <Tab id="cliente" value="cliente" label="Dados da pasta" />
                : null}
                <Tab id="auditoria" value="auditoria" label="Auditoria" />
                <Tab id="historico" value="historico" label="Históricos" />
            </Tabs>


        {tab === 'cliente' && (analista === 1 || administrador === 1 || master === 1 || gerente === 1 ||  gestor === 2)  ?
            /* Dados da pasta */
            <S.Container onSubmit={handlEditaCliente}>
                {isLoadingCliente ? 
                    <Loading />
                :
                <>
                    <S.ContainerCardForm>
                        <div className="card-form">
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Dados</S.TituloTopo>
                                    <S.Column>
                                        <FormControl variant="outlined">
                                            <TextField
                                            variant="outlined"
                                            label={'Nome da Pasta'}
                                            value={nome}
                                            placeholder="Nome da Pasta"
                                            onChange={event => setNome(event.target.value)}
                                            />
                                        </FormControl>
                                        {master === 1 ?
                                            <FormControl className="form_control" variant="outlined" >
                                                <InputLabel id="demo-simple-select-required-label">Empresa</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    onChange={event => setEmpresa(event.target.value)}
                                                    value={empresa}
                                                    label={'Empresa'}
                                                    placeholder="Empresa"
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione um empresa</em>
                                                    </MenuItem>
                                                    {empresaListaAtiva.length > 0 ? 
                                                        empresaListaAtiva.map(lista =>  (
                                                            <MenuItem key={lista.uuid} value={lista.id}>{lista.nome}</MenuItem>
                                                        ))
                                                    :null}
                                                </Select>
                                            </FormControl>
                                        : null}
                                    </S.Column>
                                </CardContent>
                            </Card>
                        
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Acesso</S.TituloTopo>
                                    <S.Column>
                                        <FormControl variant="outlined"  >
                                            <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                            <Select
                                                variant="outlined"
                                                onChange={event => setStatus(event.target.value)}
                                                value={status}
                                                label={'Status'}
                                                placeholder="Status"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione um status</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Ativo</MenuItem>
                                                <MenuItem value={2}>Inativo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </S.Column>
                                </CardContent>
                            </Card>
                        </div>
                    </S.ContainerCardForm>
                    <button type="submit">
                        {isLoadingCliente ? 'Carregando...' : 'Atualizar'}
                    </button>
                </>
                }

            </S.Container>
        : (tab === 'auditoria')  ?
            /* Checklists */
             <S.ContainerChecklist>
                <button type="button" onClick={onNovaAuditoria}>
                    Nova auditoria
                </button>
                <button type="button" className='busca' onClick={() => setBusca(true)}>
                    Buscar auditoria
                </button>
                <button className='buttonRelatorio' type="button" onClick={() => handleDownloadRelatorio()}>
                    <span>Download relatório</span>
                </button>
                {isLoadingTarefa ? 
                    <Loading />
                : 
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th className="acao">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoadingTarefa ? 
                                    <Loading />
                                : verTarefaPorUuid.length > 0 ?
                                
                                verTarefaPorUuid[0].map(listaCliente =>  (
                                    <tr
                                        key={listaCliente.uuid}
                                    >
                                        <td 
                                        onClick={() => handleClickAbreEdicaoAuditoria(listaCliente.uuid,paginationTarefa[0].pagina_atual)}>{listaCliente.id}</td>
                                        <td 
                                        onClick={() => handleClickAbreEdicaoAuditoria(listaCliente.uuid,paginationTarefa[0].pagina_atual)}>{listaCliente.acao}</td>
                                        <td 
                                        onClick={() => handleClickAbreEdicaoAuditoria(listaCliente.uuid,paginationTarefa[0].pagina_atual)}>
                                            {listaCliente.status_texto}
                                        </td>
                                        <td className="deletar"  onClick={() => handleAbreModalDeletaAuditoria(listaCliente.acao, listaCliente.uuid, listaCliente?.recorrente?.uuid)} >
                                            <p
                                            >
                                                Deletar
                                            </p>
                                        </td>
                                    </tr>
                                ))
                                : null}
                            </tbody>
                        </table>
                        <S.ContainerPagination>
                            {paginationTarefa.length ?
                            <Pagination
                                showLastButton={true}
                                showFirstButton={true}
                                color="primary"
                                count={paginationTarefa[0].ultima_pagina}
                                page={Number(paginationTarefa[0].pagina_atual)}
                                onChange={handleSelect}
                            />
                            : null}
                        </S.ContainerPagination>
                    </>
                }
            
            <Dialog
            open={openConfirm}
            onClose={handleFechaModalDeletaAuditoria}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="modal_dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Deletar
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja deletar a tarefa {nomeTarefa || ''}, essa ação não poderá ser desfeita
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={() => handleFechaModalDeletaAuditoria()} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleDeletaConfirm(uuidTarefa, recorrenteTarefa)} color="secondary">
                    Deletar
                </Button>
                </DialogActions>
            </Dialog> 
            </S.ContainerChecklist>
        : (tab === 'historico' )  ?
            /* HISTÓRICO */
            /* HISTÓRICO */
            <>
            <S.Historico>
                <button type="button" onClick={handleAbreModalHistoricoCliente}>
                    Novo histórico
                </button>
                <div className="card_historico">
                {verHistoricoPorUuid.length > 0 ? 
                <List dense={true} style={{width: '90%'}}>
                {verHistoricoPorUuid && verHistoricoPorUuid.map(lista => (
                        <>
                        <ListItem alignItems="flex-start" key={lista.uuid}>
                            <ListItemAvatar>
                            <Avatar alt={lista.usuario.nome} src={lista.usuario.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={lista.texto}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                {" por "+lista.usuario.nome}
                                </Typography>
                                <br />
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {lista.data_criacao.br}
                                </Typography>
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </>
                    ))}
                </List>
                : 
                    <div className="zero">
                        <p>Sem históricos no momento</p>
                    </div>
                }
                </div>
                {abreModalHistoricoCliente ? 
                    <HistoricoComponent
                        vinculo={id}
                        tabela="clientes"
                        fecha={handleFechaHistoricotoModalCliente} 
                        abre={abreModalHistoricoCliente}
                        acao=""
                        textoEdit=""
                        uuid=""
                    /> 
                : null }
            </S.Historico>
            </>
        : null }
        {abreModalRelatorio && <DownloadRelatorioModal 
            abre={abreModalRelatorio}
            fecha={handleFechaRelatorioModal}
            tabela={tabelaRelatorio}
        />}
        <SearchTarefaModal setBusca={setBusca} busca={busca} uuid={id} />
        </Box>       
    );
}






