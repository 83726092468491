import React, { useRef } from 'react';
import * as S from './styles';
import { useState, useEffect } from 'react';
import { useAuditoria } from '../../hooks/auditoria';
import { useResposta } from '../../hooks/resposta';
import { useTag } from '../../hooks/tag';
import { useEmail } from '../../hooks/email';
import { useAcao } from '../../hooks/acao';
import { useHistorico } from '../../hooks/historico';

import logo from '../../assets/logo_azul.svg';

import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Grid,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  ImageList,
  ImageListItem,
  Box,
  Slide,
  Typography,
  Tabs,
  Tab,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { Loading } from '../Loading/index';
import CloseIcon from '@material-ui/icons/Close';
import { FormCadastraTarefaRecorrenteModal } from '../FormCadastraTarefaRecorrenteModal/index';

import { HistoricoComponent } from '../HistoricoComponent/index';

import PrintIcon from '@material-ui/icons/Print';

import { ImagemModal } from '../../components/ImagemModal/index';

import { AdicionaTagModal } from '../../components/AdicionaTagModal/index';

import { DeletaTagModal } from '../../components/DeletaTagModal/index';
import { EditaTagModal } from '../../components/EditaTagModal/index';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useParams } from 'react-router-dom';

import { DropzoneArea } from 'material-ui-dropzone';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
import Draggable from 'react-draggable';
// import { TrendingUpRounded } from "@material-ui/icons";
import moment from 'moment';

import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { PieChart, Pie, Cell } from 'recharts';

moment.locale('pt-br');
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8A2BE2',
  '#DC143C',
  '#B8860B',
  '#C71585',
  '#D3D3D3',
  '#FFFF00',
  '#808000'
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  payload
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function FormEditaTarefa() {
  const tabela = 'tarefas';
  let componentRef = useRef();

  const {
    isLoadingAuditoria,
    VisualizarTarefaPorUUID,
    verTarefaUUID,
    usuarioLista,
    visualizarListadeUsuario,
    empresaListaAtiva,
    visualizaListadeEmpresaAtiva,
    origemListaAtiva,
    visualizaListadeOrigemAtiva,
    tarefaImagem,
    uploadArquivo,
    editaTarefa,
    addTaskTag,
    deletarTarefaRecorrente,
    deletarArquivo,
    VisualizarRespostaPorUUID,
    isLoadingResposta,
    verRespostaUUID
  } = useAuditoria();

  const { historicoPorUUID, visualizarHistoricoPorUUID, isLoadingHistorico } =
    useHistorico();

  const { tagPorUUID, VisualizarTagPorUUID, setIsLoadingEditID } = useTag();
  const { searchAcao, acaoLista } = useAcao();
  const { sendPdfRespostas, isLoading } = useResposta();

  const { emailPorUUID, VisualizarEmailPorUUID, isLoadingEditID } = useEmail();
  const { id } = useParams();

  const [tab, setTab] = useState('');
  const [auditoriaRender, setAuditoriaRender] = useState(true);
  const [respostaRender, setRespostaRender] = useState(true);
  const [historicoRender, setHistoricoRender] = useState(true);

  const [imagem, setImagem] = useState(tarefaImagem[0] || '');
  const [checkTab, setCheckTab] = useState(false);

  const [destinatario, setDestinatario] = useState('');
  const [assunto, setAssunto] = useState('');
  const [corpo_email, setCorpoEmail] = useState('');

  const [responsavel, setResponsavel] = useState(
    verTarefaUUID[0]?.responsavel?.uuid || ''
  );
  const [problema, setProblema] = useState(verTarefaUUID[0]?.problema || '');
  const [acao, setAcao] = useState(verTarefaUUID[0]?.acao || '');
  const [origem, setOrigem] = useState(verTarefaUUID[0]?.origem || '');
  const [status, setStatus] = useState(verTarefaUUID[0]?.status || '');
  const [recorrenteUUID, setRecorrenteUUID] = useState(
    verTarefaUUID[0]?.recorrente?.uuid || null
  );
  const [inicio_previsto, setInicioPrevisto] = useState(
    verTarefaUUID[0]?.inicio_previsto?.br || null
  );
  const [conclusao_prevista, setConclusaoPrevista] = useState(
    verTarefaUUID[0]?.conclusao_prevista?.br || null
  );
  const [empresa, setEmpresa] = useState(verTarefaUUID[0]?.empresa || '');

  let inicio_atual = verTarefaUUID[0]?.inicio?.br
    ? moment(verTarefaUUID[0]?.inicio?.br).format('DD/MM/YYYY HH:mm')
    : null;
  const [inicio, setInicio] = useState(inicio_atual || null);

  let conclusao_atual = verTarefaUUID[0]?.conclusao?.br
    ? moment(verTarefaUUID[0]?.conclusao?.br).format('DD/MM/YYYY HH:mm')
    : null;
  const [conclusao, setConclusao] = useState(conclusao_atual || null);
  const [recorrente, setRecorrente] = useState(
    verTarefaUUID[0]?.recorrente || null
  );
  const [dia, setCada] = useState(verTarefaUUID[0]?.recorrente?.dia || '');
  const [tipo, setFrequencia] = useState(
    verTarefaUUID[0]?.recorrente?.tipo || ''
  );
  const [dias_semana, setDiaSemana] = useState(
    String(verTarefaUUID[0]?.recorrente?.dias_semana) || []
  );
  const [openConfirm, setOpenConfirmarDeletar] = useState(false);
  const [openConfirmRecorrente, setOpenConfirmarDeletarRecorrente] =
    useState(false);

  const enviarPdfRespostas = async () => {
    console.log('ok')
    return await sendPdfRespostas(id);
  }

  const minDate = new Date(Date.now());
  const onInicioPrevisto = (date) => {
    setInicioPrevisto(moment(date).format('DD/MM/YYYY HH:mm'));
  };

  const onConclusaoPrevista = (date) => {
    setConclusaoPrevista(moment(date).format('DD/MM/YYYY HH:mm'));
  };

  const onInicio = (date) => {
    setInicio(moment(date).format('DD/MM/YYYY HH:mm'));
  };

  const onConclusao = (date) => {
    setConclusao(moment(date).format('DD/MM/YYYY HH:mm'));
  };

  const frequenciaTarefa = {
    1: 'Semanal',
    2: 'Mensal'
  };
  const diaDaSemana = [
    'Domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado'
  ];

  function exibeDiasDaSemana() {
    return JSON.parse(dias_semana.replace(/"/g, '')).map((i) => (
      <Typography key={i} value={i}>
        {diaDaSemana[i]}
      </Typography>
    ));
  }

  // recorrentes
  const handleClickDeletaRecorrente = async () => {
    setOpenConfirmarDeletarRecorrente(true);
  };

  const handleCloseConfirmRecorrente = async () => {
    setOpenConfirmarDeletarRecorrente(false);
  };
  const handleDeletaConfirmRecorrente = async () => {
    setOpenConfirmarDeletarRecorrente(false);

    let result = await deletarTarefaRecorrente(recorrenteUUID);

    if (result.erro === false) {
      setTimeout(() => {
        handleAbreModalHistorico();
      }, 1000);
    }
  };

  /*   ABRIR RECORRENTE */

  const [abreModalRecorrente, setAbreModalRecorrente] = useState(false);

  function handleAbreModalTarefa() {
    setAbreModalRecorrente(true);
  }

  function handleFechaTarefaModal() {
    setAbreModalRecorrente(false);
  }

  /*   tag */
  const [nome_tag_modal, setNomeTagModal] = useState('');
  const [tipo_tag_modal, setTipoTagModal] = useState('');
  const [uuid_modal_tag, setUUIDModal] = useState('');
  const [itemTag, setItemTagModal] = useState([]);

  const [tag, setTag] = useState(false);

  //Verifica se abre a lista de tag ou não
  const handleTag = (event) => {
    setTag(event.target.checked);
  };

  //Verifica se abre modal pra criar tag
  const [openConfirmTag, setModalTag] = useState(false);

  function handleAbreModalTag() {
    setUUIDModal(id);
    setModalTag(true);
  }

  function handleCloseConfirmTag() {
    setModalTag(false);
    setNomeTagModal('');
    setTipoTagModal('');
    setUUIDModal('');
  }

  //Verifica se abre modal pra editar tag
  const [openConfirmEditaTag, setModalEditaTag] = useState(false);

  function handleAbreEditaModalTag(titulo, itens, uuid) {
    let tipoEdita = itens.length === 0 ? '1' : '2';
    setTipoTagModal(tipoEdita);
    setNomeTagModal(titulo);
    setUUIDModal(uuid);
    setItemTagModal([itens]);

    setTimeout(() => {
      setModalEditaTag(true);
    }, '1000');
  }

  function handleCloseEditaModalTag(uuid) {
    setModalEditaTag(false);
    setTipoTagModal('');
    setNomeTagModal('');
    setUUIDModal('');
    setItemTagModal([]);
  }

  const handleGerarAuditoriaTags = () => {
    addTaskTag(id);
    setOpenConfirmTaskTag(false);
    toast.success('Auditorias criadas com sucesso!', {
      position: toast.POSITION.TOP_CENTER
    });
  };

  //Verifica se abre modal pra deletar tag
  const [openConfirmDeletaTag, setModalDeletaTag] = useState(false);

  function handleAbreDeletaModalTag(uuid) {
    setUUIDModal(uuid);
    setModalDeletaTag(true);
  }

  function handleCloseDeletaModalTag(uuid) {
    setModalDeletaTag(false);
    setUUIDModal('');
  }

  //Qr code
  const [abreImagemModal, setModalImageModal] = useState(false);
  const [linkImagem, setLinkImagem] = useState(false);

  function handleAbreImagemModal(link) {
    setModalImageModal(true);
    setLinkImagem(link);
  }

  function handleFechaImagemModal() {
    setModalImageModal(false);
    setLinkImagem('');
  }

  const [nomeArquivo, setNomeArquivo] = useState('');
  const [vinculoArquivo, setVinculoArquivo] = useState('');
  const [openConfirmTaskTag, setOpenConfirmTaskTag] = useState(false);

  //EMAIL
  const [email_automatico, setCheckedEmail] = useState(false);

  const handleEmailAutomatico = (event) => {
    setCheckedEmail(event.target.checked);
  };

  const gestor = Number(localStorage.getItem('GSPROWORK'));
  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  const handleClickOpenConfirm = async (nome, uuid) => {
    setOpenConfirmarDeletar(true);
    setVinculoArquivo(nome);
    setNomeArquivo(uuid);
  };

  const handleCloseConfirm = async () => {
    setOpenConfirmarDeletar(false);
    setVinculoArquivo('');
    setNomeArquivo('');
  };

  if (checkTab === false) {
    setTab('auditoria');
    setCheckTab(true);
  }
  const handleImages = async (files) => {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let data = {
          vinculo: id,
          imagem: files[i],
          tabela: 'tarefas'
        };
        let result = await uploadArquivo(data);
        if (result.erro === false) {
          setImagem(result?.dado[0]);
        }
      }
    }
  };

  const handleDeletaConfirm = async (vinculo, imagem) => {
    setOpenConfirmarDeletar(false);

    let result = await deletarArquivo(imagem);

    if (result.erro === false) {
      setVinculoArquivo('');
      setNomeArquivo('');

      setTimeout(() => {
        handleAbreModalHistorico();
      }, 1000);
    }
  };

  /*****************************
   * COMEÇO FUNÇÕES DA RESPOSTA
   ****************************** */

  const data = verTarefaUUID?.cabecalho?.grafico?.respostas?.dados;

  const [openConfirmImagem, setOpenConfirmarImagem] = useState(false);
  const [imagemModal, setModalImagem] = useState('');
  const handleClickOpenConfirmImagem = async (imagem) => {
    setOpenConfirmarImagem(true);
    setModalImagem(imagem);
  };

  const handleCloseConfirmImagem = async () => {
    setOpenConfirmarImagem(false);
    setModalImagem('');
  };

  /*   ABRE HISTÓRICO DE RESPOSTA */
  const [abreModalHistoricoResposta, setAbreModalHistoricoResposta] =
    useState(false);
  const [acaoResposta, setAcaoResposta] = useState('');
  const [uuidHistorico, setUUIDHistorico] = useState('');
  const [textoHistorico, setTextoHistorico] = useState('');

  function handleAbreModalHistoricoResposta(uuid, acao, textoHistorico) {
    setAcaoResposta(acao);
    setUUIDHistorico(uuid);
    setTextoHistorico(textoHistorico);
    setAbreModalHistoricoResposta(true);
  }

  function handleFechaHistoricotoModalResposta() {
    setAbreModalHistoricoResposta(false);
  }

  /*****************************
   * ABRE HISTÓRICO GERAL DA AUDITORI
   ****************************** */

  const [abreModalHistoricoCliente, setAbreModalHistoricoCliente] =
    useState(false);

  function handleAbreModalHistoricoCliente() {
    setAbreModalHistoricoCliente(true);
  }

  function handleFechaHistoricotoModalCliente() {
    setAbreModalHistoricoCliente(false);
  }

  /*****************************
   * FUNÇÕES PARA ALTERAR ABAS
   ****************************** */

  //PEGA DADOS DA RESPOSTA
  // eslint-disable-next-line react-hooks/exhaustive-deps

  //PEGA DADOS DO AUDITORIA
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchAuditoria() {
    setAuditoriaRender(false);
    let result = await VisualizarTarefaPorUUID(id);
    if (result.erro === false) {
      setResponsavel(result.dado?.responsavel?.uuid || '');
      setProblema(result.dado?.problema || '');
      setAcao(result.dado?.acao || '');
      setEmpresa(result.dado?.empresa || '');
      setOrigem(result.dado?.origem || '');
      setStatus(result.dado?.status || '');
      setRecorrenteUUID(result.dado?.recorrente?.uuid || null);
      setInicioPrevisto(result.dado?.inicio_previsto?.br || null);
      setConclusaoPrevista(result.dado?.conclusao_prevista?.br || null);
      setInicio(result.dado?.inicio?.br || null);
      setConclusao(result.dado?.conclusao?.br || null);
      setCada(result.dado?.recorrente?.dia || '');
      setFrequencia(String(result.dado?.recorrente?.tipo) || '');
      setDiaSemana(String(result.dado?.recorrente?.dias_semana) || []);
      setRecorrente(result.dado?.recorrente || null);
      setImagem(result?.imagem[0] || []);
    }
    await VisualizarTagPorUUID(id);
    await visualizarListadeUsuario({ quantidade: 1000 });
    await visualizaListadeEmpresaAtiva();
    await VisualizarEmailPorUUID(id, setDestinatario, setAssunto, setCorpoEmail, setCheckedEmail);
    await searchAcao({ codigo: id });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchResposta() {
    setRespostaRender(false);
    await VisualizarRespostaPorUUID(id);
  }
  //PEGA DADOS DA HISTÓRICO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchHistorico() {
    setHistoricoRender(false);
    await visualizarHistoricoPorUUID(id);
  }

  useEffect(() => {
    if (auditoriaRender === true && tab === 'auditoria') {
      fetchAuditoria();
    }
  }, [
    id,
    auditoriaRender,
    tab,
    fetchAuditoria,
    VisualizarTarefaPorUUID,
    isLoadingAuditoria,
    verTarefaUUID
  ]);

  useEffect(() => {
    if (respostaRender === true && tab === 'resposta') {
      fetchResposta();
    }
  }, [id, respostaRender, tab, fetchResposta, VisualizarRespostaPorUUID]);

  useEffect(() => {
    if (historicoRender === true && tab === 'historico') {
      fetchHistorico();
    }
  }, [id, historicoRender, tab, fetchHistorico, visualizarHistoricoPorUUID]);

  function handlePrimeiraAba() {
    setAuditoriaRender(true);
    fetchAuditoria();
  }

  function handleSegundaAba() {
    setRespostaRender(true);
    fetchResposta();
  }

  function handleTerceiraAba() {
    setHistoricoRender(true);
    fetchHistorico();
  }
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);

    if (newValue === 'auditoria') {
      handlePrimeiraAba();
    } else if (newValue === 'resposta') {
      handleSegundaAba();
    } else if (newValue === 'historico') {
      handleTerceiraAba();
    }
  };

  /*****************************
   * ABRIR HISTÓRICO
   ****************************** */
  const [abreModalHistorico, setAbreModalHistorico] = useState(false);

  function handleAbreModalHistorico() {
    setAbreModalHistorico(true);
  }

  function handleFechaHistoricotoModal() {
    setAbreModalHistorico(false);
  }

  /*****************************
   * EDITAR AUDITORIA
   ****************************** */

  const handleEditAuditoria = async (event) => {
    event.preventDefault();

    if (abreModalHistorico === true) {
      return false;
    }
    if (inicio_previsto === '' || inicio_previsto === 'Data inválida') {
      toast.error('Defina um data de início previsto válida', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    } else if (
      conclusao_prevista === '' ||
      conclusao_prevista === 'Data inválida'
    ) {
      toast.error('Defina um data de conclusão prevista válida', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    }

    let data = {
      cliente: verTarefaUUID[0]?.cliente?.uuid,
      responsavel,
      solicitante: String(localStorage.getItem('LPROWORK')),
      inicio_previsto: inicio_previsto ? inicio_previsto : '',
      conclusao_prevista: conclusao_prevista ? conclusao_prevista : '',
      acao,
      problema,
      empresa: verTarefaUUID[0]?.empresa,
      origem,
      inicio: inicio ? inicio : '',
      conclusao: conclusao ? conclusao : '',
      status
    };

    let dataEmail = {
      vinculo: id,
      destinatario,
      assunto,
      corpo: corpo_email,
      status: 1,
      acao: emailPorUUID.length > 0 ? 'edit' : 'add',
      emailExecuta: email_automatico
    };

    let result = await editaTarefa(data, id, dataEmail, emailPorUUID[0]?.uuid);

    if (result.erro === false) {
      setTimeout(() => {
        handleAbreModalHistorico();
      }, 1000);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          centered
          style={{ backgroundColor: 'white' }}
        >
          <Tab label="Auditoria" value="auditoria" />
          <Tab label="Respostas" value="resposta" />
          <Tab label="Históricos" value="historico" />
        </Tabs>
        {tab === 'auditoria' ? (
          <S.Container onSubmit={handleEditAuditoria}>
            {isLoadingAuditoria === true || setIsLoadingEditID === true ? (
              <Loading />
            ) : (
              <>
                <S.ContainerCardForm>
                  <S.Codigo className="coldigo">
                    Código: {verTarefaUUID[0]?.uuid}
                  </S.Codigo>
                  <div className="card-form">
                    <Card className="item">
                      <CardContent>
                        <S.TituloTopo>Dados</S.TituloTopo>
                        <S.Column>
                          <FormControl variant="outlined">
                            <FormControl>
                              <TextField
                                variant="outlined"
                                label={'ID'}
                                value={verTarefaUUID[0]?.id}
                                placeholder="ID"
                                disabled
                              />
                            </FormControl>
                          </FormControl>
                          <FormControl variant="outlined">
                            <FormControl>
                              <TextField
                                variant="outlined"
                                label={'Pasta'}
                                value={verTarefaUUID[0]?.cliente?.nome}
                                placeholder="Pasta"
                                disabled
                              />
                            </FormControl>
                          </FormControl>
                          <FormControl>
                            <TextField
                              variant="outlined"
                              label={'Nome'}
                              value={acao}
                              placeholder="Nome"
                              onChange={(event) => setAcao(event.target.value)}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              variant="outlined"
                              label={'Descrição'}
                              value={problema}
                              placeholder="Descrição"
                              onChange={(event) =>
                                setProblema(event.target.value)
                              }
                            />
                          </FormControl>
                          <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-required-label">
                              Responsável
                            </InputLabel>
                            <Select
                              variant="outlined"
                              onChange={(event) => setResponsavel(event.target.value)}
                              value={responsavel}
                              label={'Responsável'}
                              placeholder="Responsável"
                            >
                              <MenuItem value="">
                                <em>Selecione um responsável</em>
                              </MenuItem>
                              {usuarioLista
                                ? usuarioLista.map((lista) => (
                                    <MenuItem
                                      key={`status${lista.uuid}`}
                                      value={lista.uuid}
                                    >
                                      {lista.nome}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                          {/* <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-required-label">
                              Unidade
                            </InputLabel>
                            <Select
                              variant="outlined"
                              onChange={(event) =>
                                setEmpresa(event.target.value)
                              }
                              value={empresa}
                              label={'Unidade'}
                              placeholder="Unidade"
                              defaultValue=""
                            >
                              <MenuItem key={'unidade1'} value="">
                                <em>Selecione uma unidade</em>
                              </MenuItem>
                              {empresaListaAtiva.length > 0
                                ? empresaListaAtiva.map((lista) => (
                                    <MenuItem key={lista.uuid} value={lista.id}>
                                      {lista.nome}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl> */}

                          <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-required-label">
                              Status
                            </InputLabel>
                            <Select
                              variant="outlined"
                              value={status}
                              label={'Status'}
                              placeholder="Status"
                              defaultValue=""
                              disabled
                            >
                              <MenuItem key="1" value="">
                                <em>Selecione o status</em>
                              </MenuItem>
                              <MenuItem key="2" value="1">
                                <em>Pendente</em>
                              </MenuItem>
                              <MenuItem key="3" value="2">
                                <em>Concluída</em>
                              </MenuItem>
                              <MenuItem key="4" value="3">
                                <em>Atrasada</em>
                              </MenuItem>
                              <MenuItem key="5" value="4">
                                <em>Atrasada</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </S.Column>
                      </CardContent>
                    </Card>
                    <Card className="item">
                      <CardContent>
                        <S.TituloTopo>Vincule um questionário</S.TituloTopo>
                        <S.Column>
                          <FormControl
                            className="form_control"
                            variant="outlined"
                          >
                            <TextField
                              variant="outlined"
                              label={'Questionário'}
                              value={verTarefaUUID[0]?.checklist?.titulo}
                              placeholder=""
                              disabled
                            />
                          </FormControl>
                          {/* <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-required-label">
                              Origem
                            </InputLabel>
                            <Select
                              variant="outlined"
                              onChange={(event) =>
                                setOrigem(event.target.value)
                              }
                              value={origem}
                              label={'Origem'}
                              placeholder="Origem"
                              defaultValue=""
                              disabled={gestor === 1 ? true : false}
                            >
                              <MenuItem key="0" value="">
                                <em>Selecione uma origem</em>
                              </MenuItem>
                              {origemListaAtiva?.itens
                                ? origemListaAtiva?.itens.map((lista) => (
                                    <MenuItem key={lista.id} value={lista?.id}>
                                      {lista.titulo}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl> */}
                        </S.Column>
                      </CardContent>
                    </Card>
                    <Card className="item">
                      <CardContent>
                        <S.TituloTopo>Datas</S.TituloTopo>
                        <S.Column>
                          <S.Row>
                            <FormControl className="data">
                              <KeyboardDateTimePicker
                                inputVariant="outlined"
                                ampm={false}
                                label="Início previsto"
                                placeholder="Início de previsto"
                                value={moment(inicio_previsto, [
                                  'DD/MM/YYYY HH:mm'
                                ])}
                                onChange={(event) => onInicioPrevisto(event)}
                                disabled={
                                  inicio || inicio != null ? true : false
                                }
                                disablePast
                                minDate={minDate}
                                error={false}
                                helperText={null}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </FormControl>
                          </S.Row>
                          <S.Row>
                            <FormControl className="data">
                              <KeyboardDateTimePicker
                                inputVariant="outlined"
                                ampm={false}
                                label="Conclusão prevista"
                                placeholder="Conclusão prevista"
                                value={moment(conclusao_prevista, [
                                  'DD/MM/YYYY HH:mm'
                                ])}
                                onChange={(event) => onConclusaoPrevista(event)}
                                disabled={
                                  inicio || inicio != null ? true : false
                                }
                                disablePast
                                error={false}
                                helperText={null}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </FormControl>
                          </S.Row>
                          <S.Row>
                            <FormControl className="data">
                              <KeyboardDateTimePicker
                                inputVariant="outlined"
                                ampm={false}
                                label="Início"
                                placeholder="Início"
                                value={moment(inicio, ['DD/MM/YYYY HH:mm'])}
                                disabled
                                disablePast
                                error={false}
                                helperText={null}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </FormControl>
                          </S.Row>
                          <S.Row>
                            <FormControl className="data">
                              <KeyboardDateTimePicker
                                inputVariant="outlined"
                                ampm={false}
                                label="Conclusão"
                                placeholder="Conclusão"
                                value={moment(conclusao, ['DD/MM/YYYY HH:mm'])}
                                disabled
                                disablePast
                                error={false}
                                helperText={null}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </FormControl>
                          </S.Row>
                        </S.Column>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="card-form recorrencia">
                    <Card className="item">
                      <CardContent className="container_frequencia">
                        <S.Column>
                          <S.TituloTopo>Tarefas</S.TituloTopo>
                          {acaoLista && acaoLista.length > 0 ? (
                            acaoLista.map((item) => (
                              <>
                                <S.TarefaLabel
                                  onClick={() =>
                                    window.open(
                                      '/tarefa/' + item.uuid,
                                      '_blank'
                                    )
                                  }
                                >
                                  {item.acao}
                                </S.TarefaLabel>
                                <br />
                              </>
                            ))
                          ) : (
                            <InputLabel>Nenhuma tarefa encontrada</InputLabel>
                          )}
                        </S.Column>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="card-form recorrencia">
                    <Card className="item">
                      {recorrente !== null ? (
                        <>
                          <CardContent className="container_frequencia">
                            <S.Column>
                              <S.TituloTopo>Recorrência</S.TituloTopo>
                              <InputLabel>Frequência (Tipo)</InputLabel>
                              <Typography>{frequenciaTarefa[tipo]}</Typography>
                              {tipo !== '' && tipo === '2' ? (
                                <>
                                  <InputLabel>Dia</InputLabel>
                                  <Typography>{dia || ''}</Typography>
                                </>
                              ) : tipo !== '' && tipo === '1' ? (
                                <>
                                  <InputLabel className="mt20 dia_semana">
                                    Dias da semana
                                  </InputLabel>
                                  <FormControl className="mt20">
                                    <Typography>
                                      {exibeDiasDaSemana()}
                                    </Typography>
                                  </FormControl>
                                </>
                              ) : null}
                            </S.Column>
                          </CardContent>

                          <S.ButtonDeletar
                            onClick={() => handleClickDeletaRecorrente()}
                          >
                            Deletar recorrencia
                          </S.ButtonDeletar>
                        </>
                      ) : (
                        <CardContent className="container_frequencia">
                          <S.Column>
                            <S.TituloTopo>Recorrência</S.TituloTopo>
                          </S.Column>

                          <div className="botao">
                            <S.ButtonAdicionar
                              onClick={() => handleAbreModalTarefa()}
                            >
                              Adicionar recorrência
                            </S.ButtonAdicionar>
                          </div>
                        </CardContent>
                      )}
                    </Card>
                  </div>
                  {/* <div className="card-form" style={{ marginTop: '20px' }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tagPorUUID.length > 0 ? true : tag}
                            onChange={handleTag}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="Tag?"
                      />
                    </FormControl>
                  </div> */}
                  {/* {tag === true || tagPorUUID.length > 0 ? (
                    <>
                      <S.Tag>
                        <section className="titulo">
                          <h1>Adicionar tags</h1>
                          <AddOutlinedIcon
                            onClick={() => handleAbreModalTag()}
                          />
                        </section>
                        <section className="container">
                          {tagPorUUID.length > 0 &&
                            tagPorUUID.map((lista) => (
                              <div className="tag" key={lista.uuid}>
                                <p>{lista.titulo}</p>
                                <div className="icon">
                                  <EditIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleAbreEditaModalTag(
                                        lista.titulo,
                                        lista.itens,
                                        lista.uuid
                                      )
                                    }
                                  />
                                  <DeleteIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleAbreDeletaModalTag(lista.uuid)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                        </section>
                      </S.Tag>
                      {tagPorUUID.length > 0 && (
                        <S.ButtonAdicionar
                          onClick={() => setOpenConfirmTaskTag(true)}
                        >
                          Gerar auditorias das tags
                        </S.ButtonAdicionar>
                      )}
                    </>
                  ) : null} */}
                  {/* <div className="card-form" style={{ marginTop: '20px' }}>
                    <S.Tag>
                      <section className="titulo">
                        <h1>QrCodes</h1>
                      </section>
                      {tagPorUUID.length > 0 &&
                        tagPorUUID.map((lista) => (
                          <section className="container" key={lista.uuid}>
                            {lista.itens &&
                              lista.itens.map((lista) => (
                                <div className="tag" key={lista.uuid}>
                                  <p>{lista.nome}</p>
                                  <div className="icon">
                                    <PrintIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleAbreImagemModal(lista.qrcode)
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </section>
                        ))}
                    </S.Tag>
                  </div> */}
                  <div className="card-form" style={{ marginTop: '20px' }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={email_automatico}
                            onChange={handleEmailAutomatico}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="E-mail automático?"
                      />
                    </FormControl>
                  </div>
                  {email_automatico === true ? (
                    <div className="card-form recorrencia">
                      <Card className="item">
                        <>
                          <CardContent className="container_frequencia">
                            <S.Column>
                              <FormControl>
                                <TextField
                                  variant="outlined"
                                  label={'Destinatários (separado por vírgula)'}
                                  value={destinatario}
                                  placeholder="Destinatários (teste@teste.com,teste2@teste2.com"
                                  onChange={(event) =>
                                    setDestinatario(event.target.value)
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  variant="outlined"
                                  label={'Assunto'}
                                  value={assunto}
                                  placeholder=""
                                  onChange={(event) =>
                                    setAssunto(event.target.value)
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  variant="outlined"
                                  label={'Corpo Email'}
                                  value={corpo_email}
                                  placeholder=""
                                  onChange={(event) =>
                                    setCorpoEmail(event.target.value)
                                  }
                                />
                              </FormControl>
                            </S.Column>
                          </CardContent>
                        </>
                      </Card>
                    </div>
                  ) : null}
                  <S.ContainerImage>
                    <h2>Anexar arquivo</h2>
                    <Grid container className="drop-container">
                      <Grid item xs={12}>
                        <div>
                          <DropzoneArea
                            showFileNames={false}
                            showPreviews={false}
                            showPreviewsInDropzone={false}
                            acceptedFiles={[
                              'image/jpeg,image/jpg,image/png,image/gif',
                              'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.odt'
                            ]}
                            onChange={handleImages}
                            dropzoneText="Clique e selecione ou arraste arquivos para realizar upload"
                            filesLimit={100}
                            maxFileSize={5120000} // 5120 KB
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <p>Arquivos enviados</p>
                    <Grid container className="imagem_enviada" spacing={2}>
                      {imagem.length > 0
                        ? imagem.map((lista, index) => (
                            <Grid key={index} item xs={4}>
                              <Paper>
                                <p>{lista.imagem}</p>
                                <IconButton
                                  edge="end"
                                  target="blank"
                                  href={lista.imagem}
                                  aria-label="download"
                                >
                                  <CloudDownload />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleClickOpenConfirm(
                                      lista.vinculo,
                                      lista.uuid
                                    )
                                  }
                                  edge="end"
                                  aria-label="delete"
                                >
                                  <Delete />
                                </IconButton>
                              </Paper>
                            </Grid>
                          ))
                        : 'Sem arquivos enviados'}
                    </Grid>
                  </S.ContainerImage>
                </S.ContainerCardForm>
                <button type="submit">
                  {isLoadingAuditoria ? 'Carregando...' : 'Atualizar'}
                </button>
              </>
            )}
          </S.Container>
        ) : tab === 'resposta' ? (
          <S.ContainerCardForm>
            {isLoadingResposta === true || isLoading === true ? (
              <Loading />
            ) : (
              <S.ContainerResposta>
                <h2>Respostas (usuários)</h2>
                <ReactToPrint
                  trigger={() => <a className="imprimir">Imprimir Tela</a>}
                  content={() => componentRef.current}
                />               
                <a
                  className="imprimir"
                  href={`https://eprowork.nyc3.cdn.digitaloceanspaces.com/respostas_pdf/pdf_${id}.pdf`}
                  target="_blank"
                  download
                  rel="noreferrer"
                >
                  Download do PDF
                </a>
                <a
                  className="imprimir"
                  onClick={enviarPdfRespostas}
                  rel="noreferrer"
                >
                  Enviar PDF por e-mail
                </a>
                <div
                  id="listaResposta"
                  ref={(el) => (componentRef.current = el)}
                >
                  {verRespostaUUID?.cabecalho?.responsavel?.nome ? (
                    <S.Column style={{ marginBottom: '20px' }}>
                      <div className="cabecalho">
                        <div className="cabecalhoLeft">
                          <div className="titulo">
                            <span>Responsável:</span>
                            <p>
                              Nome:{' '}
                              {verRespostaUUID?.cabecalho?.responsavel?.nome}
                            </p>
                            <p>
                              Email:{' '}
                              {verRespostaUUID?.cabecalho?.responsavel?.email}
                            </p>
                          </div>
                          <div className="titulo">
                            <span>Solicitante:</span>
                            <p>
                              Nome:{' '}
                              {verRespostaUUID?.cabecalho?.solicitante?.nome}{' '}
                            </p>
                          </div>
                          <div className="titulo">
                            <span>Checklist:</span>
                            <p>
                              Título: {verRespostaUUID?.cabecalho?.tarefa?.acao}
                            </p>
                            <p>
                              Descrição:{' '}
                              {verRespostaUUID?.cabecalho?.tarefa?.problema}
                            </p>
                            <p>
                              Início previsto:{' '}
                              {
                                verRespostaUUID?.cabecalho?.tarefa
                                  ?.inicio_previsto
                              }
                            </p>
                            <p>
                              Conclusão prevista:{' '}
                              {
                                verRespostaUUID?.cabecalho?.tarefa
                                  ?.data_prevista
                              }
                            </p>
                          </div>
                          <div className="titulo">
                            <span>Início:</span>
                            <p>{verRespostaUUID?.cabecalho?.tarefa?.inicio}</p>
                          </div>
                          <div className="titulo">
                            <span>Conclusão:</span>
                            <p>
                              {verRespostaUUID?.cabecalho?.tarefa?.conclusao}{' '}
                            </p>
                          </div>
                          {/* {verRespostaUUID?.cabecalho?.tarefa?.latitude && verRespostaUUID?.cabecalho?.tarefa?.longitude && (
                            <a href={`https://www.google.com/maps?q=${verRespostaUUID?.cabecalho?.tarefa?.latitude}%2C${verRespostaUUID?.cabecalho?.tarefa?.longitude}&ll=${verRespostaUUID?.cabecalho?.tarefa?.latitude},${verRespostaUUID?.cabecalho?.tarefa?.longitude}&zoom=12`} target="_blank" rel="noreferrer">                        
                              <button>
                                Local em que foi respondido
                              </button>
                            </a>
                          )} */}
                          
                        </div>
                        <div className="cabecalhoRight">
                          <header>
                            <figure>
                              <img src={logo} alt="logo" />
                            </figure>
                          </header>
                          <div className="cabecalhoRespostas">
                            <table>
                              <tr>
                                <td className="title">Resposta</td>
                                <td className="title">%</td>
                                <td className="title"></td>
                              </tr>
                              {verRespostaUUID?.cabecalho?.grafico?.respostas
                                ?.dados &&
                                verRespostaUUID?.cabecalho?.grafico?.respostas?.dados.map(
                                  (item, index) => (
                                    <tr>
                                      <td>{item.name}</td>
                                      <td>
                                        {(
                                          (item.value /
                                            verRespostaUUID?.cabecalho?.grafico
                                              ?.respostas?.total) *
                                          100
                                        ).toFixed(0)}
                                        %
                                      </td>
                                      <td
                                        className="cor"
                                        style={{
                                          backgroundColor: COLORS[index]
                                        }}
                                      ></td>
                                    </tr>
                                  )
                                )}
                            </table>
                            {verRespostaUUID?.cabecalho?.grafico?.respostas
                              ?.dados ? (
                              <PieChart width={340} height={340}>
                                <Pie
                                  data={
                                    verRespostaUUID?.cabecalho?.grafico
                                      ?.respostas?.dados
                                  }
                                  cx={120}
                                  cy={140}
                                  labelLine={false}
                                  label={renderCustomizedLabel}
                                  outerRadius={120}
                                  fill="#8884d8"
                                  dataKey="value"
                                  nameKey="name"
                                >
                                  {verRespostaUUID?.cabecalho?.grafico?.respostas?.dados.map(
                                    (entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                      />
                                    )
                                  )}
                                </Pie>
                              </PieChart>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </S.Column>
                  ) : null}
                  {verRespostaUUID?.respostas &&
                    Object.keys(verRespostaUUID.respostas).map((key, index) => (
                      <S.ColumnWhite>
                        <S.TituloGrande className="grupo">
                          <span>{key}</span>
                        </S.TituloGrande>
                        {key &&
                          verRespostaUUID.respostas[key].map((lista, index) => (
                            <div className="background-respostas">
                              <S.TituloGrande>
                                {' '}
                                <span>Data da resposta</span>:{' '}
                                {lista?.data_criacao?.br}
                              </S.TituloGrande>
                              {/* <S.TituloGrande>
                                      <span>Peso: {lista?.pergunta?.peso}</span>
                                    </S.TituloGrande> */}
                              <S.Respostas>
                                <span>Pergunta:</span>
                                <p>{lista?.pergunta?.texto}</p>
                              </S.Respostas>
                              <S.Respostas>
                                <span>Resposta:</span>
                                <p>{lista?.resposta}</p>
                              </S.Respostas>
                              <S.Respostas>
                                <span>Observação:</span>
                                <p>
                                  {lista?.observacao
                                    ? lista?.observacao
                                    : 'Nenhuma'}
                                </p>
                              </S.Respostas>
                              <S.ContainerRespostaImagem>
                                <ImageList
                                  sx={{ width: 500, height: 450 }}
                                  cols={3}
                                  rowHeight={164}
                                >
                                  {lista.imagens &&
                                    lista.imagens.map((item) => (
                                      <ImageListItem key={item.uuid}>
                                        <img
                                          src={item.imagem}
                                          srcSet={item.imagem}
                                          alt={lista?.usuario?.nome}
                                          loading="lazy"
                                          onClick={() =>
                                            handleClickOpenConfirmImagem(
                                              item.imagem
                                            )
                                          }
                                        />
                                      </ImageListItem>
                                    ))}
                                </ImageList>
                              </S.ContainerRespostaImagem>

                              <S.Comentario>
                                {lista?.historico?.usuario?.nome ? (
                                  <>
                                    <S.TituloGrande
                                      style={{ marginBottom: '10px' }}
                                    >
                                      {' '}
                                      <span>Comentário</span>:{' '}
                                    </S.TituloGrande>
                                    <div className="conteudo">
                                      <p className="nome">
                                        {' '}
                                        <Avatar
                                          alt={lista?.historico?.usuario?.nome}
                                          src={
                                            lista?.historico?.usuario?.avatar
                                          }
                                        />{' '}
                                        {lista?.historico?.usuario?.nome}{' '}
                                      </p>
                                      <p className="historico">
                                        {lista?.historico?.data_criacao?.br}
                                      </p>{' '}
                                      <br />
                                      <p className="historico">
                                        {lista?.historico?.texto}
                                      </p>
                                    </div>
                                  </>
                                ) : null}
                                {!lista?.historico?.texto ? (
                                  <button
                                    onClick={() =>
                                      handleAbreModalHistoricoResposta(
                                        lista?.uuid,
                                        'add',
                                        ''
                                      )
                                    }
                                  >
                                    Comente
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleAbreModalHistoricoResposta(
                                        lista?.historico?.uuid,
                                        'edit',
                                        lista?.historico?.texto
                                      )
                                    }
                                  >
                                    Comente
                                  </button>
                                )}
                              </S.Comentario>
                            </div>
                          ))}
                      </S.ColumnWhite>
                    ))}
                </div>
              </S.ContainerResposta>
            )}
          </S.ContainerCardForm>
        ) : tab === 'historico' ? (
          <S.Historico>
            {isLoadingHistorico === true ? (
              <Loading />
            ) : (
              <>
                <button type="button" onClick={handleAbreModalHistoricoCliente}>
                  Novo histórico
                </button>
                <div className="card_historico">
                  {historicoPorUUID ? (
                    <List dense={true} style={{ width: '90%' }}>
                      {historicoPorUUID &&
                        historicoPorUUID.map((lista) => (
                          <>
                            <ListItem alignItems="flex-start" key={lista.uuid}>
                              <ListItemAvatar>
                                <Avatar
                                  alt={lista.usuario.nome}
                                  src={lista.usuario.avatar}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={lista.texto}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {' por ' + lista.usuario.nome}
                                    </Typography>
                                    <br />
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {lista.data_criacao.br}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        ))}
                    </List>
                  ) : (
                    <div className="zero">
                      <p>Sem históricos no momento</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </S.Historico>
        ) : null}
      </Box>
      {abreModalHistorico ? (
        <HistoricoComponent
          vinculo={id}
          tabela={tabela}
          fecha={handleFechaHistoricotoModal}
          abre={abreModalHistorico}
        />
      ) : null}
      {abreModalRecorrente ? (
        <FormCadastraTarefaRecorrenteModal
          tarefa={id}
          fecha={handleFechaTarefaModal}
          abre={abreModalRecorrente}
        />
      ) : null}
      {/* //CRIAR tag */}
      {openConfirmTag ? (
        <AdicionaTagModal
          abre={openConfirmTag}
          fecha={handleCloseConfirmTag}
          uuid={id}
        />
      ) : null}
      {/* //DELETAR tag */}
      {openConfirmDeletaTag ? (
        <DeletaTagModal
          abre={openConfirmDeletaTag}
          fecha={handleCloseDeletaModalTag}
          uuid={uuid_modal_tag}
          uuidAuditoria={id}
        />
      ) : null}

      {openConfirmEditaTag ? (
        <EditaTagModal
          abre={openConfirmEditaTag}
          fecha={handleCloseEditaModalTag}
          uuidAuditoria={id}
          itens={itemTag}
          nomeTag={nome_tag_modal}
          tipoTag={tipo_tag_modal}
          uuidTagModal={uuid_modal_tag}
          acao={'editar'}
        />
      ) : null}

      {abreImagemModal ? (
        <ImagemModal
          abre={abreImagemModal}
          fecha={handleFechaImagemModal}
          link={linkImagem}
        />
      ) : null}

      {abreModalHistoricoResposta ? (
        <HistoricoComponent
          vinculo={uuidHistorico}
          tabela="respostas"
          fecha={handleFechaHistoricotoModalResposta}
          abre={abreModalHistoricoResposta}
          acao={acaoResposta}
          uuid={id}
          textoEdit={textoHistorico}
        />
      ) : null}
      {abreModalHistoricoCliente ? (
        <HistoricoComponent
          vinculo={id}
          tabela="tarefas"
          fecha={handleFechaHistoricotoModalCliente}
          abre={abreModalHistoricoCliente}
        />
      ) : null}
      <Dialog
        open={openConfirmImagem}
        onClose={handleCloseConfirmImagem}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={true}
        TransitionComponent={Transition}
      >
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}></Box>
            <Box>
              <IconButton onClick={handleCloseConfirmImagem}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="imagem_modal">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={imagemModal} alt="imagem" height="80%" />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Deletar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar esta imagem, essa ação não poderá ser
            desfeita
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleCloseConfirm()}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleDeletaConfirm(vinculoArquivo, nomeArquivo)}
            color="secondary"
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmRecorrente}
        onClose={handleCloseConfirmRecorrente}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Deletar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar a recorrência? Essa ação não poderá
            ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseConfirmRecorrente()}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleDeletaConfirmRecorrente(id)}
            color="secondary"
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmTaskTag}
        onClose={() => setOpenConfirmTaskTag(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Criar auditorias por tag
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja criar as auditorias?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmTaskTag(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleGerarAuditoriaTags()} color="secondary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
